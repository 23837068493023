<template lang='pug'>
    #Deals.main-page.page-noScroll.pb-0.position-relative
        loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
        //- win & lost start
        .position-absolute.win(v-show='showResult' :class='winDrag ? "dragoverStyle" : ""')
            h3.position-absolute.t-white.txt WIN
        .d-flex.justify-center.align-start.position-absolute.winbox(v-if='showResult')
            draggable(v-show='showResult' :list='winList' :class='winDrag ? "hidden" : ""' v-on:drop.native='winDrag = false' v-on:dragleave.native='winDrag = false' v-on:dragover.native='winDrag = true' group="opportunity_set" style ='height:100%; width: 100%;' @add='winDrop()' )
        .position-absolute.lost(v-show='showResult' :class='loseDrag ? "dragoverStyle" : ""')
            h3.position-absolute.t-white.txt LOSE
        .d-flex.justify-center.align-start.position-absolute.losebox(v-if='showResult')
            draggable(v-show='showResult' :list='loseList' :class='loseDrag ? "hidden" : ""' v-on:drop.native='loseDrag = false' v-on:dragleave.native='loseDrag = false' v-on:dragover.native='loseDrag = true' group="opportunity_set" style ='height:100%; width: 100%;' @add='loseDrop()')
        //- win & lost end 
        v-row(v-if='!loading')#page-head.d-flex.align-center.justify-md-space-between
            v-col.d-flex.align-center.justify-space-between.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='3' lg='3' style='z-index:1;')
                .d-flex.align-center
                    h1.my-0(draggable='true' style='min-width:80px;') {{$t('DEAL.TITLE')}}
                    v-btn.ml-3.add-deal-btn(@click='showAddDealDialog(0)' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') icon-add
                    v-btn.ml-2.add-deal-btn(v-if='mode.list && selectedRows.length>0' @click='showBulkEditDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') icon-edit
                    v-btn.ml-2.add-deal-btn(v-if='mode.list && selectedRows.length == 1' @click='showCopyDealDialog(selectedRows[0])' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') far fa-copy
                    v-btn.ml-2.add-deal-btn(v-if='mode.list && selectedRows.length>0' @click='onDelete()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') icon-remove
                    v-btn.ml-2.add-deal-btn(v-if='selectedRows.length>0' @click='follow()' :title="$t('FOLLOW.OPPTY')" width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='20') mdi-star
                .btn-group.ml-3(v-if='mode.list')
                    v-text-field(v-model='searchDeals' height='36' flat dense solo prepend-inner-icon='icon-search')
            v-col.px-0.pt-4.pt-md-2(cols='8' md='9' lg='9')
                v-row.align-center.justify-end.z-index-100
                    v-col.pl-md-0.btn-group-with-label(cols='auto')
                        v-progress-circular(size='18' v-if='loading_content || changeListStage' indeterminate color='primary')
                        span(v-else) $ {{ getFormatAmount(amount) }}・{{ dealsCount }} {{$t('DEAL.OPPTIES')}}
                    v-col.pl-md-0.btn-group-with-label(cols='auto')
                        .btn-switch
                            v-btn(:ripple='false' @click='viewKanban' :min-width='btnWidth' :width='btnWidth' height='36' :color="mode.kanban? 'primary':'white'"  depressed tile :dark='!mode.kanban')
                                v-icon(size='16') icon-board
                            v-btn(:ripple='false' @click='viewList' :min-width='btnWidth' :width='btnWidth' height='36' :color="mode.list? 'primary':'white'" depressed tile :dark='!mode.list')
                                v-icon(size='16') icon-list
                            v-btn(:ripple='false' @click='viewForecast' :min-width='btnWidth' :width='btnWidth' height='36' :color="mode.forecast? 'primary':'white'" depressed tile :dark='!mode.forecast')
                                v-icon(size='24') mdi-crystal-ball
                                
                    v-col.pl-md-0.btn-group-with-label(cols='2')
                        label {{$t('DEAL.PROCESS')}}
                        .btn-group
                            v-combobox(v-model='selectStageType' :items='stageTypeList' item-value='id' item-text='stage_type_name' index='0' height='36' flat dense solo @change='reloadStageTypeAndData')
                            v-btn.border-left(:ripple='false' min-width='36' width='36' height='36' color='white' depressed tile dark @click='addProcess')
                                v-icon(size='16') icon-add
                    v-col.pl-md-1.btn-group-with-label(cols='2')
                        label {{$t('DEAL_DETAIL.OWNER')}}
                        v-combobox.bg-white(v-model='selectOwner' :items='ownerList' item-value='id' item-text='fullName' max-width='100' height='36' outlined dense @change='reloadData')
                    v-col.pl-md-1.pr-md-0.btn-group-with-label(cols='2')
                        label {{$t('FILTERSETTING.CS')}}
                        .btn-group
                            setting_btn(:filter_list='filter_list' :tables='tables' :currentTable='currentTable' :default_filter='default_filter' tab='Opportunity' :mode='mode' :slc-stg-type='selectStageType' :stage-type-list='stageTypeList' :is_staff='is_staff' @emitView='onEmitView' @emitFilter='onEmitFilter' :customViews='customViews')
                    v-col.pr-md-0.pl-md-1.d-flex(cols='auto' v-if='!noAnyStageType')
                        importExportBtn(@emitFilter='onEmitFilter' :page='"Opportunity"' :selectStageType='selectStageType' :search='searchDeals' :filter='default_filter.id' :tab='pagemode' :owner='selectOwner.id')
                        refreshBtn(@EmitRefresh='onEmitRefresh')
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode.kanban')
            v-row(class='d-flex justify-space-between flex-nowrap height_100')
                v-col(col='auto').d-flex
                    v-row(v-if="selectStageType.stage_type_name == $t('DEAL.NO_DEAL_STAGE')" height='100%') 
                        v-col.d-flex.align-center.justify-center.pa-0( height='100%')
                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='30%' height='30%')
                                h5.ma-0.t-black {{$t('DEAL.NO_DEAL')}}
                    .pa-0.pr-2.px-md-2(v-for='subitem, index in kanbanData.stagedef_set' :key='index')
                        v-card.deal-group(min-width='280' max-width='460' outlined flat)
                            v-system-bar(height='8' :class='`customcolor`' :style='getColor(subitem.stage_color)')
                            v-card-title.pa-0
                                v-badge(color='secondary' :content="subitem.stage_oppty_count" inline='inline')
                                    h4.ellipsis {{ subitem.stage_name }}
                            p.mt-1.mb-0.deals-totalAmount(v-if="subitem.stage_amount != null") $ {{ getFormatAmount(subitem.stage_amount) }}
                            v-card-text(v-bind:id='"drag-" + subitem.id').pa-0
                                draggable.h-100(:list='subitem.oppty_set' group="opportunity_set" draggable='.canDrag' animation=500 :key='subitem.id' @end='dragEnd(subitem)')
                                    v-card.deals-item(v-for='dealsItem, index in subitem.oppty_set' :key='index' flat :class=' canDrag_kanban ? "canDrag":"" ' @dragstart='dragStart(dealsItem)')
                                        v-card-title.d-flex.align-center.justify-space-between.pa-0
                                            v-row.ellipsis
                                                v-col.d-flex.align-center.px-0(cols='10')
                                                    h5.ellipsis(:title='dealsItem.name') {{ dealsItem.name }}
                                                v-tooltip(bottom)
                                                    template(v-slot:activator='{ on, attrs }')
                                                        v-col(cols='2').d-flex.justify-end.align-center.px-0
                                                            v-btn.border-none.mr-1(width='14' height='14' :class='`customcolor`' v-if='dealsItem.stage_light_color != undefined' :style='getColor(dealsItem.stage_light_color.color)' v-bind='attrs' v-on='on' fab depressed)
                                                    span(v-if='dealsItem.stage_light_color != undefined') {{ dealsItem.stage_light_color.tip }}
                                        v-card-text.pa-0(@click='showToDealDialog(dealsItem, subitem, index)')
                                            v-chip.my-2.text-white(v-if="dealsItem.forecast_category != null" color='primary' small lighten-3) {{ getItemNameFromList(forecast_category_List, dealsItem.forecast_category) }}
                                            p.mt-1.mb-0.ellipsis(:title="dealsItem.oppty_org != null ? dealsItem.oppty_org.name : dealsItem.oppty_contact.name") {{ dealsItem.oppty_org != null ? dealsItem.oppty_org.name : dealsItem.oppty_contact.__chineseName__ }}											
                                            p.mt-1.mb-0 {{$t('OPPTYCARD.EXPECTED_CLOSE_DATE')}}：{{ dealsItem.expected_close_date }}
                                            v-row.d-flex.align-center(no-gutters='no-gutters')
                                                v-col(cols='9')
                                                    span(v-if="dealsItem.amount != null")  $ {{ getFormatAmount(dealsItem.amount) }}
                                                v-col.text-right(cols='3')
                                                    v-avatar(color='aliceBlue' size='28')
                                                        span(v-if="dealsItem.owner != undefined && !dealsItem.owner.usersetting.photo") {{ dealsItem.owner.last_name }}
                                                        img(v-if='dealsItem.owner != undefined && dealsItem.owner.usersetting.photo' :src='dealsItem.owner.usersetting.photo' :alt='dealsItem.owner.last_name')
                            v-card-actions.text-center
                                v-btn.mx-auto(@click='showAddDealDialog(subitem)' :ripple='false' width='32' height='32' color='grey lighten-1' depressed dark fab='fab')
                                    v-icon(size='16') icon-add
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode.list')
            .data-area
                v-data-table.deals-table(v-if='!loading_content && !changeListStage' :headers='dealsHeaders' :header-props="{id:'value',sortIcon: 'mdi mdi-arrow-up'}" :items='listOpptyList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" :loading='loading_edit' multi-sort height='100%' hide-default-footer='hide-default-footer' fixed-header v-model="selectedRows" show-select item-key='id' v-resize-table="headerData")
                    //- 燈號
                    //- template(v-slot:item.data-table-select='{ item }')
                        v-system-bar(:color='item.stage_light_color.color')

                    //- 機會名稱
                    template(v-slot:item.name='{ item }')
                        div(class='d-flex justify-left' :title='item.name')
                            followIcon(:follow='item.follow')
                            .inline-edit-cell.justify-start
                                a(v-if='!!item.name' :href='"/Deal/" + item.id' class='ellipsis ml-8') {{ item.name }}
                            inlineEditDialog(field_type='opptyname' page='deals' editProp='name' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 結案原因
                    template(v-slot:item.closed_reason='{ item }')
                        .ellipsis(v-if='item.closed_reason > 0')
                            .t-black.ellipsis {{getItemNameFromList(closeReasonList ,item.closed_reason)}}
                    
                    //- 狀態
                    template(v-slot:item.status='{ item }')
                        .ellipsis
                            v-chip.my-2.text-black(v-if='item.status=="OPEN"' small) Open
                            v-chip.my-2.text-white(v-else-if='item.status=="WON"' color='green' small) Won
                            v-chip.my-2.text-white(v-else-if='item.status=="LOSE"' color='red' small) Lose
                    
                    //- 客戶名稱
                    template(v-slot:item.oppty_contact='{ item }' )
                        div(v-if='item.oppty_org != null' class=' d-flex justify-left position-relative' :title='item.oppty_org.name' )
                            .inline-edit-cell(v-if='item.oppty_org != null')
                                a(class='ml-8 ellipsis' :title='item.oppty_org.name' :href='"/Account/" + item.oppty_org.id') {{ item.oppty_org.name }}
                            inlineEditDialog(field_type='select_oppty_org' page='deals' editProp='oppty_org' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                        div(v-if='item.oppty_contact_id != null' class=' d-flex justify-left position-relative' :title='item.oppty_contact.__chineseName__' )
                            .inline-edit-cell(v-if='item.oppty_contact_id != null')
                                a(class='ml-8 ellipsis' :title='item.oppty_contact.name' :href='"/Contacts/" + item.oppty_contact.id' ) {{ item.oppty_contact.__chineseName__ }}
                            inlineEditDialog(field_type='select_required_contact' page='deals' editProp='oppty_contact' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                        
                    //- 現在階段
                    template(v-slot:item.current_stage='{ item }')
                        div(class='d-flex justify-center ' :title='item.stage_name')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' :title='item.stage_name') {{ item.stage_name }}
                            inlineEditDialog(field_type='select' page='deals' editProp='current_stage_id' :editItem='item' :selectList='selectStageTypeList' item-text="stage_name" item-value="id" @emitInlineEdit='onEmitInlineEdit')

                    //- 預測類別
                    template(v-slot:item.forecast_category='{ item }')
                        div(class='d-flex justify-center' :title='getItemNameFromList(forecast_category_List, item.forecast_category)')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' v-if='item.forecast_category != null') {{ getItemNameFromList(forecast_category_List, item.forecast_category) }}
                            inlineEditDialog(field_type='select' page='deals' editProp='forecast_category' :editItem='item' :selectList='forecast_category_List' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 商業流程
                    template(v-slot:item.stage_type='{ item }')
                        .ellipsis
                            .t-black(:title='item.stage_type_name') {{ item.stage_type_name }}
                    
                    //- 主要聯絡人
                    template(v-slot:item.primary_contact='{ item }')
                        div( class='d-flex justify-center' :title='item.primary_contact_zh' )
                            .inline-edit-cell
                                a(class='ellipsis ml-8' :title='item.primary_contact_zh' :href='"/Contacts/" + item.primary_contact_id' ) {{ item.primary_contact_zh }}
                            inlineEditDialog(field_type='select_contact' page='deals' editProp='primary_contact' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 金額
                    template(v-slot:item.amount='{ item }')
                        div(class='d-flex justify-center ' :title='getFormatAmount(item.amount)')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' v-if='item.amount') {{ getFormatAmount(item.amount) }}
                            inlineEditDialog(field_type='amount' page='deals' editProp='amount' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 預期結案日期
                    template(v-slot:item.expected_close_date='{ item }')
                        div(class='d-flex justify-center ' :title='item.expected_close_date')
                            .inline-edit-cell
                                span(class='ellipsis ml-8') {{ item.expected_close_date }}
                            inlineEditDialog(field_type='m_date' page='deals' editProp='expected_close_date' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 結案日期
                    template(v-slot:item.closed_date='{ item }')
                        .t-black.ellipsis(:title='item.closed_date' v-if='item.closed_date && item.closed_reason > 0') {{ item.closed_date }}
                    
                    //- 確認預算
                    template(v-slot:item.budget_confirmed='{ item }')
                        div(class='d-flex justify-center ' :title='item.budget_confirmed')
                            .inline-edit-cell
                                v-icon(size="18" color="green" class='ellipsis ml-8' v-if='item.budget_confirmed') fas fa-check
                            inlineEditDialog(field_type='checkbox' page='deals' editProp='budget_confirmed' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 客戶預算
                    template(v-slot:item.customer_budget='{ item }')
                        div(class='d-flex justify-center ' :title='getFormatAmount(item.customer_budget)')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' v-if='item.customer_budget') {{ getFormatAmount(item.customer_budget) }}
                            inlineEditDialog(field_type='customer_budget' page='deals' editProp='customer_budget' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 相關活動
                    template(v-slot:item.primary_campaign_id='{ item }')
                        div(class='d-flex justify-center ' :title='item.primary_campaign_name')
                            .inline-edit-cell
                                a(class='ml-8 ellipsis' v-if='item.primary_campaign_id != null' :href='"Campaign/"+item.primary_campaign_id' target="_blank") {{ getItemNameFromListById(campaignList, item.primary_campaign_id) }}
                            inlineEditDialog(field_type='autocomplete' page='deals' editProp='primary_campaign_id' :selectList='campaignList' item-text="name" item-value="id" :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 機會擁有者
                    template(v-slot:item.owner='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.owner.last_name+" "+item.owner.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start')
                                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                                    v-icon(v-if="item.owner == null") mdi-account 
                                    v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.owner.usersetting.photo')
                                a(v-if='!!item.owner' :href='onMail(item.owner.email)' class='ellipsis') {{ item.owner.last_name+' '+item.owner.first_name }}
                            inlineEditDialog(field_type='select' page='deals' editProp='owner' :editItem='item' :selectList='ownerList_inlineEdit' item-text="fullName" @emitInlineEdit='onEmitInlineEdit')
                    
                    //- 建立時間
                    template(v-slot:item.created_on='{ item }')
                        .ellipsis
                            .t-black(:title='item.created_on' v-if='item.created_on') {{ new Date(item.created_on).toLocaleString() }}
                    
                    //- 建立者
                    template(v-slot:item.created_by='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.created_by.last_name+" "+item.created_by.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start ml-4')
                                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                                    v-icon(v-if="item.created_by == null") mdi-account 
                                    v-icon(v-else-if="item.created_by.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.created_by.usersetting.photo')
                                a(v-if='!!item.created_by' :href='onMail(item.created_by.email)' class='ellipsis') {{ item.created_by.last_name+' '+item.created_by.first_name }}
                    
                    //- 更新時間
                    template(v-slot:item.updated_on='{ item }')
                        .ellipsis
                            .t-black(:title='item.updated_on' v-if='item.updated_on') {{ new Date(item.updated_on).toLocaleString() }}
                    
                    //- 更新者
                    template(v-slot:item.updated_by='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.updated_by.last_name+" "+item.updated_by.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start ml-4')
                                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                                    v-icon(v-if="item.updated_by == null") mdi-account 
                                    v-icon(v-else-if="item.updated_by.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.updated_by.usersetting.photo')
                                a(v-if='!!item.updated_by' :href='onMail(item.updated_by.email)' class='ellipsis') {{ item.updated_by.last_name+' '+item.updated_by.first_name }}
                    
                    //- 描述 description
                    template(v-slot:item.description='{ item }')
                        div(class='d-flex justify-center ' :title='item.description')
                            .inline-edit-cell
                                span(class='ml-8 ellipsis') {{item.description}}
                            inlineEditDialog(field_type='textarea' page='deals' editProp='description' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 客戶需求 customer_needs
                    template(v-slot:item.customer_needs='{ item }')
                        div(class='d-flex justify-center ' :title='item.customer_needs')
                            .inline-edit-cell
                                span(class='ml-8 ellipsis') {{item.customer_needs}}
                            inlineEditDialog(field_type='textarea' page='deals' editProp='customer_needs' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 客製化欄位 custom_field
                    template(v-for='e, index in cf_header' v-slot:[`item.${e.value}`]='{ item }')
                        div(v-if='item.custom_field_list != null' class='d-flex justify-center')
                            .inline-edit-cell
                                v-icon(v-if='e.type == "bol" && item.custom_field_list[`${e.prop}`] === true' class='ml-8 ellipsis' size="18" color="green" ) fas fa-check 
                                span(v-else-if='e.type == "bol" && item.custom_field_list[`${e.prop}`] === false')
                                span(v-else-if='e.type == "opt" || e.type == "tst"' class='ml-8 ellipsis' :title='getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`])') {{ getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`]) }}
                                span(v-else-if='e.type == "mlt"' class='ml-8 ellipsis' :title='getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`])') {{ getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`]) }}
                                span(v-else class='ml-8 ellipsis' :title='item.custom_field_list[`${e.prop}`]') {{ item.custom_field_list[`${e.prop}`] }}
                            inlineEditDialog(v-if='e.type != "opt" && e.type != "mlt" && e.type != "tst"' page='deals' :field_type='"custom_field_"+e.type' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' @emitInlineEdit='onEmitInlineEdit_cf')
                            inlineEditDialog(v-if='e.type == "opt"' page='deals' field_type='custom_field_opt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                            inlineEditDialog(v-if='e.type == "mlt"' page='deals' field_type='custom_field_mlt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                            inlineEditDialog(v-if='e.type == "tst"' page='deals' field_type='custom_field_tst' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].treeList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf' :treeSelectList='CFdef[`${e.prop}`].optList')
                    //- 執行
                    template(v-slot:item.action='{ item }')
                        .ellipsis
                            v-btn( icon='icon' @click='showToDealDialog(item)')
                                v-icon(size='18') icon-chatmoney
                            v-btn(icon='icon' @click='onDelete(item)')
                                v-icon(size='18') icon-remove
                            
                label.text-right.mr-40(v-if='!loading_content && !changeListStage') {{(page-1)*itemPerPage+1}} － {{currentData}} ／ {{pageDataCount}} 筆
                v-pagination.py-1.mb-3(v-if='!loading_content && !changeListStage' v-model='page' :length='pageCount' circle ) 
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode.forecast && !loading')
            v-row.d-flex.justify-left.flex-nowrap.height_100
                v-col.d-flex(col='auto')
                    v-row(v-if="selectStageType.stage_type_name == $t('DEAL.NO_DEAL_STAGE')" height='100%') 
                        v-col.d-flex.align-center.justify-center.pa-0( height='100%')
                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='30%' height='30%')
                                h5.ma-0.t-black {{$t('DEAL.NO_DEAL')}}
                    .pa-0.pr-2.px-md-2( v-for='item, index in forecastviewData.stagedef_set' :key='index')
                        v-card.deal-group.pt-0(min-width='280' max-width='460' outlined flat)
                            v-system-bar(height='8' color='primary')
                            .pa-0.py-2
                                v-card-title.pa-0.px-2.py-3.d-flex.justify-space-between
                                    h4.ellipsis {{ item.monthName }}
                                    h6.t-green {{ item.year }}
                                v-card-subtitle.text-left.pa-0
                                    v-row
                                        v-col(cols='12').pa-0.pa-2
                                            v-row.justify-between
                                                v-col(cols='6').text-left.pa-0.pt-2
                                                    p.f-smaller.mt-1.mb-0.deals-totalAmount Closed:  
                                                v-col(cols='6').text-right.pa-0.pt-2
                                                    p.f-smaller.mt-1.mb-0.deals-totalAmount(v-if='showForecast') $ {{ getFormatAmount(item.total.closed) }}
                                            v-row.justify-between
                                                v-col(cols='6').text-left.pa-0.pb-2
                                                    p.f-smaller.mb-0.deals-totalAmount Commit: 
                                                v-col(cols='6').text-right.pa-0.pb-2
                                                    p.f-smaller.mb-0.deals-totalAmount(v-if='showForecast') $ {{ getFormatAmount(item.total.commit) }}
                                            hr.ma-0.my-2
                                            v-row
                                                v-col(cols='12').text-right.pa-0.py-2
                                                    p.f-smaller.mt-1.mb-0.deals-totalAmount(v-if='showForecast') $ {{ getFormatAmount((item.total.closed * 100 + item.total.commit * 100) / 100) }}
                                        v-col(cols='12' ).pa-0.px-2.pb-2
                                            p.f-smaller.mt-1.mb-0.deals-totalAmount(v-if='showForecast') Upside: $ {{ getFormatAmount(item.total.upside) }}
                                            p.f-smaller.mt-1.mb-0.deals-totalAmount(v-if='showForecast') Best Case: $ {{ getFormatAmount(item.total.bestCase) }}
                            v-card-text(v-bind:id='"drag-" + item.year+"-"+item.month').pa-0.forecast_card_height
                                draggable.h-100(:list='item.opportunities' group="opportunity_set" draggable='.canDrag' :key='item.id' @end='dragEnd_Forcast(item)')
                                    v-card.deals-item(v-for='opptyItem, index in item.opportunities' :key='index' flat :class='canDrag_forecast && (opptyItem.closed_reason == null || opptyItem.closed_reason == 0) ? "canDrag" : ""' animation=500 @dragstart='dragStart_Forcast(opptyItem)' @dragend='dragEnd_Forcast(item)')
                                        v-card-title.d-flex.align-center.justify-space-between.pa-0
                                            v-row.ellipsis
                                                v-col.d-flex.align-center.px-0(cols='10')
                                                    h5.ellipsis(:title='opptyItem.name') {{ opptyItem.name }}
                                                v-tooltip(bottom)
                                                    template(v-slot:activator='{ on, attrs }')
                                                        v-col(cols='2').d-flex.justify-end.align-center.px-0
                                                            v-btn.border-none.mr-1(width='14' height='14' :class='`customcolor`' v-if='opptyItem.stage_light_color != undefined' :style='getColor(opptyItem.stage_light_color.color)' v-bind='attrs' v-on='on' fab depressed)
                                                    span(v-if='opptyItem.stage_light_color != undefined') {{ opptyItem.stage_light_color.tip }}
                                        v-card-text.pa-0(@click='showToDealDialog(opptyItem, item, index)')
                                            v-chip.my-2.text-white(v-if='opptyItem.closed_reason == 1' color='green' small) Won
                                            v-chip.my-2.text-white(v-else color='primary' small lighten-3) {{ getItemNameFromList(forecast_category_List, opptyItem.forecast_category) }}
                                            p.mt-1.mb-0.ellipsis(:title="opptyItem.oppty_org != null ? opptyItem.oppty_org.name : opptyItem.oppty_contact.name") {{ opptyItem.oppty_org != null ? opptyItem.oppty_org.name : opptyItem.oppty_contact.__chineseName__ }}
                                            p.mt-1.mb-0 {{$t('DEAL_DETAIL.EXPECTED_CLOSE_DATE')}}：{{ opptyItem.expected_close_date }}
                                            v-row.d-flex.align-center(no-gutters='no-gutters')
                                                v-col(cols='9')
                                                    span $ {{ getFormatAmount(opptyItem.amount) }}
                                                v-col.text-right(cols='3')
                                                    v-avatar(color='aliceBlue' size='28')
                                                        span(v-if="opptyItem.owner != undefined && !opptyItem.owner.usersetting.photo") {{ opptyItem.owner.last_name }}
                                                        img(v-if='opptyItem.owner != undefined && opptyItem.owner.usersetting.photo' :src='opptyItem.owner.usersetting.photo' :alt='opptyItem.owner.last_name')
                            v-card-actions.text-center
                                v-btn.mx-auto(@click='showAddDealDialog(0, item)' :ripple='false' width='32' height='32' color='grey lighten-1' depressed dark fab='fab')
                                    v-icon(size='16') icon-add
        v-navigation-drawer.v-navigation-drawer-bulk-edit(v-model='bulkEditDialog'  v-if='bulkEditDialog' absolute bottom temporary right width="400" zIndex='99999')
            bulk-edit-dialog(@emitBulkEditDialog='onEmitBulkEditDialog' :editItems='selectedRows' :stageTypeId='this.selectStageType')
        v-dialog(v-model='activityDialog' width='470' data-app='true')
            activity-dialog(@emitActivityDialog='onEmitActivityDialog')
        v-dialog(v-model='addDealDialog' max-width='600'  v-if='addDealDialog')
            add-deal(@emitAddDealDialog='onEmitAddDealDialog' @emitNewDealData='onEmitNewDealData' :add-deal='addDealData')
        v-dialog(v-model='editDealDateDialog' max-width='400' persistent @keydown.esc='onEmitEditDealDate(false)')
            edit-deal-date(@emitEditDealDate='onEmitEditDealDate' :oppty-data='editOpptyData' :edit-date='editDate' :should-reopen='editDealDateDialog')
        v-dialog(v-model='toDealDialog' max-width='1200' height='416' v-if='toDealDialog')
            to-deal(@emitToDealDialog='onEmitToDealDialog' :oppty-data='opptyData')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='loseReason' width='400' persistent @keydown.esc='onEmitLoseReasonDialog(false)' v-if='loseReason')
            lose-reason(@emitLoseReasonDialog='onEmitLoseReasonDialog' :lose-deal='loseDealData' :should-reopen='loseReason')
        v-dialog(v-model='addProcessDialog' max-width='500' content-class='setting-dialog' v-if='addProcessDialog' scrollable)
            add-process-dialog(@emitAddProcessDialog='onEmitAddProcessDialog' @emitAddProcessData='onEmitAddProcessData' :stage-type='stageTypeData' :stage-def='stageDefData' pageType='DEAL')
        v-dialog(v-model='editTableDialog' width='360' content-class='statusDialog' v-if='editTableDialog')
            edit-table-dialog(@emitEditTableDialog='onEmitEditTableDialog' @emitEditTable='onEmitEditTable' :custom-views='customViews' :edit-data='currentTable' :stage-type='selectStageType' :edit-mode='editMode')

</template>

<script>
    import refreshBtn from "@/components/Common/refreshBtn";
    import activityDialog from '@/components/Dialog/activityDialog';
    import editTableDialog from "@/components/Dialog/Setting/editTableDialog";
    import addDeal from '@/components/Dialog/Deal/addDeal';
    import toDeal from '@/components/Dialog/Deal/toDeal';
    import editDealDate from '@/components/Dialog/Deal/editDealDate';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import loseReason from '@/components/Dialog/Deal/loseReason';
    import addProcessDialog from '@/components/Dialog/Setting/addProcessDialog';
    import draggable from 'vuedraggable';
    import forecast_category_List from "@/array/the_forecastCategoryList";
    import get_oppty_closed_reason_func from "@/array/the_closeReasonList";
    import opptysFieldList from "@/array/the_opptysFieldList";
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import StageDataService from "@/services/StageDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import OpportunityDataService from "@/services/OpportunityDataService";
    import CustomViewDataService from "@/services/CustomViewDataService";
    import CustomFilterDataService from "@/services/CustomFilterDataService"; 
    import CustomFieldDataService from "@/services/CustomFieldDataService";
    import TreeOptionsDataService from "@/services/TreeOptionsDataService";
    import importExportBtn from "@/components/Common/importExportBtn";
    import setting_btn from "@/components/Common/setting_btn";
    import i18n from '/common/plugins/vue-i18n.js';
    import bulkEditDialog from "@/components/Dialog/BulkEdit/opptyBulkEditDialog";
    import inlineEditDialog from '@/components/Common/inlineEditDialog';
    import CampaignDataService from "@/services/CampaignDataService";
    import BulkEditDataService from "@/services/BulkEditDataService";
    import FollowDataService from '../services/FollowDataService';
    import followIcon from "@/components/Common/followIcon";

    export default {
        name: 'Deals',
        components: {
            activityDialog,
            addDeal,
            toDeal,
            editDealDate,
            loadingOverlay,
            messageDialog,
            successDialog,
            errorDialog,
            loseReason,
            addProcessDialog,
            draggable,
            editTableDialog,
            refreshBtn,
            importExportBtn,
            setting_btn,
            bulkEditDialog,
            inlineEditDialog,
            followIcon,
        },
        data() {
            return {
                is_staff: JSON.parse(window.localStorage.getItem('is_staff')),
                user: JSON.parse(window.localStorage.getItem('user')),
                loading: false,
                loading_content: false,
                loading_edit: false,
                mode:{
                    kanban: true,
                    list: false,
                    forecast: false,
                },
                stageTypeList: [],
                selectStageType: {},
                selectStageTypeList:[],
                pagemode:"kanban",
                forecast_category_List: forecast_category_List,
                opptysFieldList: opptysFieldList,
                closeReasonList: [],
                ownerList: [
                    {
                        id: 0,
                        fullName: i18n.t('GENERAL.ALL')
                    }
                ],
                ownerList_inlineEdit: [],
                process: [],
                selectOwner: {
                    id: 0,
                    fullName: i18n.t('GENERAL.ALL')
                },
                searchDeals: '',
                dealsHeaders: [],
                awaitingSearch: false, 
                sortBy:[],
                sortByForSearch:[],
                sortByDesc:[],
                pageDataCount:null,
                page: 1,
                pageCount: 0,
                currentData:0,
                itemPerPage: 50,
                opportunityList: null,
                btnWidth: (100/3)+'%',
                default_process: null,
                // Dialog
                activityDialog: false,
                addDealDialog: false,
                editDealDateDialog: false,
                toDealDialog: false,
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                addDealData: {},
                opptyData: {},
                forecastList: [],
                sumAmount: '',
                sumDeals: '',
                successMode: '',
                stageDefIndex: '',
                newDealData: {},
                amount: 0,
                dealsCount: 0,
                opptyByOwnerList: [],
                showResult: false,
                winList:[],
                loseList: [],
                currentType: 0,
                currentOwner: 0,
                loseReason: false,
                loseDealData: {},
                dragStageIndex: 0,
                opptyByTypeList: [],
                kanbanOpptyList: [],
                listOpptyList: [],
                editOpptyData: { },
                deleteItem: {},
                isWin: false,
                isLose: false,
                winDrag: false,
                loseDrag: false,
                addProcessDialog: false,
                stageTypeData: {},
                stageDefData: [],
                showForecast: false,
                filterOpptyList: [],
                stageData: {},
                // Custom View
                tables: [],
                editTableDialog: false,
                currentTable: {},
                customViews: [],
                cf_header: [],
                editMode: 'EDIT',
                // Kanban
                kanbanData: {},
                originStage: {},
                originAmount: 0,
                canDrag_kanban: true,
                // ForecastView
                forecastviewData: {},
                newMonthObj: {},
                oldMonthObj: {},
                originYear: 1911,
                originMonth: 1,
                drag_Forcast_month:[],
                drag_Forcast_year:null,
                editDate: '',
                getnewData:false,
                default_filter:null,
                filter_list : [],
                first_run:false,
                allfilter_list:[],
                roll_set_kanban:{},
                roll_set_forcast:{},
                have_add_forcast:false,
                system_default_filter: null,
                canDrag_forecast: true,
                column_resize: false,
                customField:null,
                selectedRows:[],
                bulkEditDialog:false,
                campaignList: null,
                CFdef: null,
                changeListStage: false,
                deleteOpptyIds: '',
                noAnyStageType:false,
                headerData:{
                    id:"",
                    name:"",
                    value:{},
                    page:""
                },
            }
        },
        async beforeCreate(){
            this.closeReasonList = await get_oppty_closed_reason_func();
        },
        async created() {
            this.loading = true;
            
            // get default process
            try {
                this.default_process = this.user.default_value.default_process;
            } 
            catch (error) {
                this.default_process = null;
            }

            await StageDataService.stageList("deal")
            .then(response => {
                let gotDefault_process = false;
                // get stage list and  default process
                if (response.data.length != 0) {
                    response.data.forEach(item => {
                        item.stagedef_set = item.stagedef_set.sort(function(a, b){ 
                            return a.sequence_number-b.sequence_number
                        });
                        if(gotDefault_process == false){
                            if(this.default_process == null || this.default_process.opportunity == null){
                                if(item.is_admin_default){
                                    this.selectStageType = item;
                                    this.default_process = item;
                                    gotDefault_process = true;
                                }
                            }
                            else{
                                if(item.id == this.default_process.opportunity){
                                    this.selectStageType = item;
                                    this.default_process = item;
                                    gotDefault_process = true;
                                }
                            }
                        }
                        
                        this.stageTypeList.push(item);
                    });
                    try{
                        this.selectStageTypeList = this.selectStageType.stagedef_set;
                    }catch{
                        this.selectStageTypeList = [];
                }
                } 
                else {
                    let item = {}
                    item.stage_type_name = i18n.t('DEAL.NO_DEAL_STAGE');
                    this.stageTypeList.push(item);
                    this.noAnyStageType = true;
                }
                if(Object.keys(this.selectStageType).length == 0){
                    this.selectStageType = this.stageTypeList[0];
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            });


            // get default filter
            let filter_id = this.getDefaultFilter("Opportunity",this.selectStageType.id)
            await this.getFilterList()
            await (this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list,this.system_default_filter))

            await OrganizationDataService.getchangeowner('opportunities')
            .then(response => {
                response.data.forEach(item => {
                    if (item.first_name != null) {
                        item.fullName = item.last_name + " " + item.first_name;
                    } else {
                        item.fullName = item.last_name;
                    }
                    this.ownerList.push(item);
                    this.ownerList_inlineEdit.push(item);
                });
                this.selectOwner = this.ownerList[0];
            })
            .catch(response => {
                this.showErrorDialog(response)
            })

            await CustomViewDataService.getCustomView('Opportunity')
            .then(response => {
                this.customViews = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            })

            this.getTables();
            await this.getKanbanData();
            await this.getCampaginList()
            // await this.addScrollEvent();
            await (this.loading = false);
            await (this.first_run = true);
        },
        methods: {
            follow(){
                let add_list=[];
                let delete_list=[];
                for(let i = 0; i < this.selectedRows.length; i++)
                    {
                    if(!this.selectedRows[i].follow){
                        add_list.push(this.selectedRows[i].id);
                    }else{
                        delete_list.push(this.selectedRows[i].id)
                    }
                    }
                let data = {
                    "add_list":add_list,
                    "delete_list":delete_list,
                    "class_name":"Opportunity"
                }
                FollowDataService.bulkCreateDeleteFollow(data)
                .then((response)=>{
                    for(let i in response.data.add_list){
                    let oppty = this.listOpptyList.filter(el => el.id == response.data.add_list[i]);
                    oppty[0].follow = true;
                    }
                    for(let i in response.data.delete_list){
                    let oppty = this.listOpptyList.filter(el => el.id == response.data.delete_list[i]);
                    oppty[0].follow = false;
                    }
                    this.selectedRows = [];
                })
            },
            getCampaginList(){
                CampaignDataService.selectlist()
                .then((response)=>{
                    this.campaignList = response.data;
                })
            },
            onEmitInlineEdit(listItem, editItem, prop, value){
                this.loading_edit = true;
                // 更新階段
                if(prop == "current_stage_id"){
                    let stageData ={"current_stage_id":value}
                    OpportunityDataService.opptySummaryUpdate(editItem.id, stageData)
                    .then((response)=>{
                        let stageName = this.selectStageTypeList.filter(el=>el.id==response.data.current_stage_id)[0].stage_name
                        listItem.current_stage_id = value;
                        listItem.current_stage = {"stage_name":stageName};
                        listItem.stage_name = stageName;
                    })
                    .catch(response =>{
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                        listItem.updated_by = updated_by;
                        editItem.updated_by = updated_by;
                        listItem.updated_on = new Date();
                        editItem.updated_on = new Date();
                        this.loading_edit = false;
                    })
                    return;
                }

                listItem[prop] = value;
                editItem[prop] = value;
                let editData = JSON.parse(JSON.stringify(editItem));
                prop == 'owner' ? delete editData.stage_type : '';
                delete editData.owner.usersetting;

                OpportunityDataService.editOpptyDetail(editData)
                .then((response)=>{
                    if(prop == "oppty_org"){
                        listItem.oppty_org = response.data.oppty_org;
                    }
                    if(prop == "oppty_contact"){
                        listItem.oppty_contact = {
                            "id":response.data.oppty_contact.id,
                            "__chineseName__":response.data.oppty_contact.__chineseName__
                        };
                        listItem.oppty_contact_id = response.data.oppty_contact.id;
                    }
                    if(prop == "primary_contact"){
                        if(Object.keys(value).length > 0){
                            listItem.primary_contact = {
                                "id":response.data.primary_contact.id,
                                "__chineseName__":response.data.primary_contact.__chineseName__
                            };
                            listItem.primary_contact_id = response.data.primary_contact.id;
                            listItem.primary_contact_zh = response.data.primary_contact.full_name;
                            if(response.data.primary_contact.first_name !=""){
                                listItem.primary_contact_en = response.data.primary_contact.first_name + " " +response.data.primary_contact.last_name;
                            }else{
                                listItem.primary_contact_en = response.data.primary_contact.last_name;
                            }
                        }else{
                            listItem.primary_contact = {
                                "id":null,
                                "__chineseName__": " "
                            };
                            listItem.primary_contact_id = null;
                            listItem.primary_contact_en = " ";
                            listItem.primary_contact_zh = "";
                        }
                    }

                    let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                    listItem.updated_by = updated_by;
                    editItem.updated_by = updated_by;
                    listItem.updated_on = response.data.updated_on;
                    editItem.updated_on = response.data.updated_on;
                })
                .catch((response)=>{
                    console.log(response);
                })
                .finally(()=>{
                    this.loading_edit = false;
                })
            },
            onEmitInlineEdit_cf(listItem,editItem){
                CustomFieldDataService.updateOpptyCustomFieldDetail(editItem.id,editItem)
                .catch((response)=>{
                    console.log(response);
                })
                .finally(()=>{
                    let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                    listItem.updated_by = updated_by;
                    editItem.updated_by = updated_by;
                    listItem.updated_on = new Date();
                    editItem.updated_on = new Date();
                    this.loading_edit = false;
                })
            },
            onEmitView(view, mode){
                this.currentTable = view;
                if(mode != undefined){
                    if(mode=="edit"){
                        this.editMode = 'EDIT';
                        this.showEditTableDialog();
                    }
                    else if(mode=='copy'){
                        this.editMode = 'COPY';
                        this.showEditTableDialog();
                    }
                    else if(mode=='add'){
                        const userOwnCV = this.customViews.find(e => e.owner != null);
                        let item = {
                            name: view.name,
                            fields: view.fields,
                            is_default: false,
                            stage_type_id: this.selectStageType.id,
                            owner: userOwnCV.owner,
                            id : view.view_id
                        }
                        view.owner = userOwnCV.owner;
                        
                        userOwnCV.definition.push(item);
                        this.getHeader();
                    }
                }else{
                    this.getHeader();
                }
            },
            onEmitFilter(filter, situation){
                
                if(situation == 'cancelEdit'){
                    for(let prop in this.default_filter){
                        let p = prop.toString();
                        this.$set(this.default_filter, p, filter[prop]) 
                    }
                    return;
                }
                if(situation == 'applyFilter' && filter != undefined){
                    filter.owner_id = filter.owner;
                    this.default_filter = filter;
                    return;
                }
                if(situation == 'addFilter' && filter != undefined){
                    this.filter_list.push(filter);
                    this.allfilter_list.push(filter);
                    filter.owner_id = filter.owner;
                    this.default_filter = filter;
                    return;
                }
                if(situation == 'confirmEdit_exportDialog'){
                    let oldData = this.filter_list.find(e => e.id == filter.id);
                    for(let prop in oldData){
                        let p = prop.toString();
                        this.$set(oldData, p, filter[prop]) 
                    }
                    return;
                }
            },
            async getFilterList(){
                await CustomFilterDataService.list()
                .then((response)=>{
                    this.allfilter_list = response.data;
                    this.filter_list = this.allfilter_list.filter(el => el.class_name == "Opportunity" && el.stage_type_id == this.selectStageType.id || el.class_name == "Opportunity" && el.stage_type_id == 'all')
                })
                .finally(()=>{
                    this.system_default_filter = this.filter_list.find(e => e.default && e.stage_type_id == this.selectStageType.id);
                })
            },
            async getCFdef(){
                let cfDefList = [];
                let treeSelectData = [];
                await TreeOptionsDataService.list().then((response)=>{
                    treeSelectData = response.data;
                })
                await CustomFieldDataService.getCustomFieldDetail("Opportunity").then((response)=>{
                    if(response.data[0].definition != null && response.data[0].definition.length >0){
                        cfDefList = response.data[0].definition.filter(el=>el.stage_type_id == this.selectStageType.id || el.stage_type_id == "all");
                    }
                    if(cfDefList.length>0){
                        this.customField = {};
                        for(let i in cfDefList){
                            for(let c in cfDefList[i].data){
                                cfDefList[i].data[c]['array_num'] = parseInt(i)
                            }
                            this.customField = Object.assign(this.customField,cfDefList[i].data);
                            this.CFdef = this.customField;
                        }
                        for(let prop in this.CFdef){
                            if(prop.includes('tst')){
                                let treeData = treeSelectData.filter(el=>el.id == this.CFdef[prop].tree_select_id)[0]
                                let optList = treeData.option_set;
                                optList = Object.entries(optList).map((arr) => (
                                {
                                    key: arr[0],
                                    name: arr[1],
                                }));
                                this.CFdef[prop]['optList'] = optList;
                                this.CFdef[prop]['treeList'] = treeData.value;
                            }
                            if(prop.includes('opt') || prop.includes('mlt')){
                                let optList = Object.entries(this.CFdef[prop].option_set)
                                .map((arr) => (
                                {
                                    key: arr[0],
                                    name: arr[1],
                                }));
                                this.CFdef[prop]['optList'] = optList;
                            }
                        }
                    }
                })
            },
            listener_kanban_function(evevt){
                const params = evevt.target._params
                let e = params.e
                let current_id = params.current_id
                let stage_type = params.stage_type
                let owner = params.owner
                let dom = params.dom
                let filter = params.filter
                if(Math.round(dom.scrollTop)/(dom.scrollHeight - dom.offsetHeight)>=0.99){                            
                    if(e.can_roll>0 && !this.getnewData)
                    {
                    setTimeout(() => {
                    this.roll_set_kanban[current_id] = this.roll_set_kanban[current_id]+1
                    OpportunityDataService.kanban_stage("kanban_stage", stage_type, owner, 1, this.searchDeals, this.itemPerPage, this.sortByForSearch,current_id,this.roll_set_kanban[current_id],filter)
                    .then(response => {
                        e.oppty_set = e.oppty_set.concat(response.data);
                        e.can_roll = e.can_roll-1
                    })
                    this.getnewData = false
                    }, 500);
                    this.getnewData = true;
                    }
                }
            },
            async addScrollEvent(){
                this.kanbanData.stagedef_set.forEach(e => {
                    let current_id = String(e.id)
                    let stage_type = this.kanbanData.id
                    let owner = this.selectOwner.id
                    this.roll_set_kanban[current_id] = 0
                    let dom = document.getElementById(`drag-${e.id}`);
                    let filter = this.default_filter ?  this.default_filter.id : ''
                    dom._params = { e,current_id,stage_type,owner,dom,filter }
                    dom.scrollTop = 0
                    dom.addEventListener('scroll', this.listener_kanban_function)
                })
            },
            async removeScrollEvent(){
                this.kanbanData.stagedef_set.forEach(e => {
                    let dom = document.getElementById(`drag-${e.id}`);
                    dom.removeEventListener('scroll',this.listener_kanban_function)
                })
            },
            listener_forecast_function(evevt){
                const params = evevt.target._params
                let e = params.e
                let current_date = params.current_date
                let stage_type = params.stage_type
                let owner = params.owner
                let dom = params.dom
                let filter = params.filter
                if(Math.round(dom.scrollTop)/(dom.scrollHeight - dom.offsetHeight)>=0.99){
                    if(e.can_roll>0 && !this.getnewData)
                    {
                        setTimeout(() => {
                        this.roll_set_forcast[current_date] = this.roll_set_forcast[current_date]+1
                        OpportunityDataService.forecast_stage("forecast_date", stage_type, owner, 1, this.searchDeals, this.itemPerPage, this.sortByForSearch,current_date,this.roll_set_forcast[current_date],filter)
                        .then(response => {
                            e.opportunities = e.opportunities.concat(response.data);
                            e.can_roll = e.can_roll-1
                        })
                        this.getnewData = false
                        }, 500);
                        this.getnewData = true;
                    }
                }
            },
            async addScrollEvent_forecast(){
                this.forecastviewData.stagedef_set.forEach(e => {
                    let current_date = String(e.year)+'-'+( "0" + e.month ).substr( -2 );
                    let stage_type = this.selectStageType.id
                    let owner = this.selectOwner.id
                    this.roll_set_forcast[current_date] = 0
                    let dom = document.getElementById(`drag-${e.year}-${e.month}`);
                    let filter = this.default_filter ?  this.default_filter.id : ''
                    dom._params = { e,current_date,stage_type,owner,dom,filter }
                    dom.scrollTop = 0
                    dom.addEventListener('scroll',this.listener_forecast_function)
                    this.have_add_forcast = true
                })
            },
            async removeScrollEvent_forecast(){
                this.forecastviewData.stagedef_set.forEach(e => {
                    let dom = document.getElementById(`drag-${e.year}-${e.month}`);
                    dom.removeEventListener('scroll',this.listener_forecast_function)
                })
            },
            async getListData(){
                this.loading_content = true;
                let stage_type;
                let owner;
                if (typeof(this.selectStageType) != "object"){
                    stage_type = this.selectStageType;
                }
                else{
                    stage_type = this.selectStageType.id;
                    this.selectStageTypeList = this.selectStageType.stagedef_set;
                }

                if (typeof(this.selectOwner) != "object")
                    owner = this.selectOwner;
                else
                    owner = this.selectOwner.id;

                this.opportunityList = [];
                this.listOpptyList = [];
                this.showForecast = false;
                let filter = this.default_filter ?  this.default_filter.id : '';
                await OpportunityDataService.list(this.pagemode, stage_type, owner, this.page, this.searchDeals, this.itemPerPage, this.sortByForSearch,filter)
                .then(response => {
                    if(this.mode.list) {
                        this.opportunityList = response.data.list;
                        this.listOpptyList = response.data.list;
                        this.pageCount = response.data.total_page;
                        this.pageDataCount = response.data.pageDataCount;
                        this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage;
                        this.listOpptyList.forEach(data => {
                            data.owner.fullName = data.owner.first_name == null ? data.owner.last_name : data.owner.last_name +" "+ data.owner.first_name;
                        });
                    } else {
                        this.opportunityList = response.data.stagedef_set[0].deal_set;
                    }
                    this.amount = response.data.total_amount;
                    this.dealsCount = response.data.total_count;
                
                    this.loading_content = false;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    if(this.mode.list) {
                        this.changeListStage = false;
                    }
                })

                if (!this.mode.list && this.stageTypeList[0].id != undefined) {
                    await this.filterByStageTypeAndOwner() //過濾stagetype and owner
                    this.opportunityList.forEach(oppty => {
                        this.forSearch(oppty);
                    });
                    await (this.showForecast = true)

                }
                // this.sortByForSearch = []
            },
            async getKanbanData() {
                if(this.noAnyStageType){
                    return;
                }
                this.loading_content = true;
                let stage_type;
                let owner;
                if (typeof(this.selectStageType) != "object")
                    stage_type = this.selectStageType;
                else
                    stage_type = this.selectStageType.id;

                if (typeof(this.selectOwner) != "object")
                    owner = this.selectOwner;
                else
                    owner = this.selectOwner.id;

                let filter = this.default_filter ?  this.default_filter.id : '';
                await OpportunityDataService.list(this.pagemode, stage_type, owner, this.page, this.searchDeals, this.itemPerPage, this.sortByForSearch,filter)
                .then(response => {
                    this.kanbanData = response.data;
                    this.amount = this.kanbanData.total_amount;
                    this.dealsCount = this.kanbanData.oppty_count;
                });
                await(this.loading_content = false);
                
                try{
                    await this.removeScrollEvent()
                    await this.addScrollEvent()
                }catch{
                    await this.addScrollEvent()
                }

            },
            async getForecastViewData() {
                this.loading_content = true;
                let stage_type;
                let owner;
                if (typeof(this.selectStageType) != "object")
                    stage_type = this.selectStageType;
                else
                    stage_type = this.selectStageType.id;

                if (typeof(this.selectOwner) != "object")
                    owner = this.selectOwner;
                else
                    owner = this.selectOwner.id;

                // await OpportunityDataService.getForecastview(stage_type, owner)
                let filter = this.default_filter ?  this.default_filter.id : ''
                await OpportunityDataService.list(this.pagemode, stage_type, owner, this.page, this.searchDeals, this.itemPerPage, this.sortByForSearch,filter)
                .then(response => {
                    this.forecastviewData = response.data;
                    this.amount = this.forecastviewData.total_amount;
                    this.dealsCount = this.forecastviewData.oppty_count;
                });
              
                try{
                    await this.removeScrollEvent_forecast()
                    await this.addScrollEvent_forecast()
                }catch{
                    await this.addScrollEvent_forecast()
                }                
                await(this.loading_content = false);
            },
            get_current_filter(){
                let filter_id = this.getDefaultFilter("Opportunity",this.selectStageType.id)
                this.filter_list = this.allfilter_list.filter(el => el.class_name == "Opportunity" && el.stage_type_id == this.selectStageType.id || el.class_name == "Opportunity" && el.stage_type_id == 'all')
                this.system_default_filter = this.filter_list.find(e => e.default);
                this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list,this.system_default_filter)
            },
            async reloadStageTypeAndData() {
                // check user input data is from combobox
                if(typeof(this.selectStageType) == 'object' && typeof(this.selectOwner) == 'object'){
                    await this.get_current_filter()
                    if (this.mode.kanban == true){
                        this.getKanbanData();
                        this.getTables();
                    }
                    else if (this.mode.list == true){
                        this.changeListStage = true;
                        this.getListData();
                        this.getTables();
                    }
                    else if (this.mode.forecast == true){
                        this.getForecastViewData();
                        this.getTables();
                    }
                }
            },
            reloadData(){
                // check user input data is from combobox
                if(typeof(this.selectStageType) == 'object' && typeof(this.selectOwner) == 'object'){
                    if (this.mode.kanban == true){
                        this.getKanbanData();
                    }
                    else if (this.mode.list == true){
                        this.getListData();
                    }
                    else if (this.mode.forecast == true){
                        this.getForecastViewData();
                    }
                }
            },
            getItemPerPage(){
                // let windowH = window.innerHeight - 200; //table高度
                // let objH = 70;
                // return parseInt(windowH/objH);
            },
            onMail: function (mail) {
                return "mailto:" + mail;
            },
            forSearch(oppty) {
                if (oppty.oppty_org != null) {
                    oppty.client = oppty.oppty_org.name;
                } else {
                    oppty.client = oppty.oppty_contact.__chineseName__;
                }
                oppty.current_stage_name = oppty.current_stage.stage_name;
                oppty.forecast_category_name = this.getItemNameFromList(this.forecast_category_List, oppty.forecast_category);
                oppty.owner_name = oppty.owner.last_name + oppty.owner.first_name;
                return oppty;
            },
            // kanban view
            async kanbanFilter() {
                this.kanbanOpptyList = this.filterOpptyList.filter(oppty => {
                    return oppty.closed_reason == null || oppty.closed_reason == 0;
                });
            },
            async arrangeKanbanDeals(opptyList) {
                let current_type = this.stageTypeList[this.currentType]
                let def = 0;
                this.selectStageType = current_type;
                current_type.stagedef_set.forEach(step => {
                    let currentOpportunity = opptyList.filter(oppty => {
                        return oppty.current_stage.id == step.id
                    })
                    let stage_def = current_type.stagedef_set[def]
                    stage_def.opportunity_set = currentOpportunity;
                    this.$set(this.selectStageType.stagedef_set[def], 'dealNumbers', currentOpportunity.length.toString());
                    let allAmount = 0;
                    for(let i = 0; i<stage_def.dealNumbers; i++) {
                        allAmount = allAmount + parseFloat(stage_def.opportunity_set[i].amount);
                    }
                    stage_def.totalAmount = allAmount;
                    def++
                });
                this.selectStageType = current_type;
            },
            // forecast view
            async forecastFilter(){
                let thisMonthMs = this.getThisMonthMs();
                this.forecastList = this.filterOpptyList.filter(oppty => {
                    return oppty.forecast_category != null && Date.parse(oppty.expected_close_date) >= thisMonthMs;
                });

                this.forecastList = this.forecastList.filter(oppty => {
                    return oppty.closed_reason == 0 || oppty.closed_reason == 1 || oppty.closed_reason == null;
                })
            },
            async arrangeForecastDeals(opptyList){
                let current_type = this.stageTypeList[this.currentType];
                let selectStageType = {
                        id: current_type.id,
                        stage_type_name: current_type.stage_type_name,
                        stagedef_set: []
                };

                if(opptyList.length > 0){
                    selectStageType.stagedef_set = this.getforecast_Stagedef_set(opptyList);
                    let wonArray = [];
                    
                    for(let i  = 0; i < opptyList.length; i++){
                        
                        let oppty = opptyList[i];
                        if(oppty.closed_reason == 1){
                            wonArray.push(oppty);
                        }
                        else{
                            let opptyYear = oppty.expected_close_date.substr(0,4);
                            let opptyMonth = oppty.expected_close_date.substr(5,2);

                            let isRightObJ = (e) => e.year === opptyYear && e.month === opptyMonth;
                            let index = selectStageType.stagedef_set.findIndex(isRightObJ);

                            let rightObj = selectStageType.stagedef_set[index];
                            rightObj.opportunities.push(oppty);
                            this.countForecastTotal(oppty, rightObj);
                        }
                    }
                    
                    for(let i = 0; i < selectStageType.stagedef_set.length; i++){
                        selectStageType.stagedef_set[i].opportunities.sort(function(a, b){
                            return b.forecast_category - a.forecast_category;
                        })
                    }

                    for(let i  = 0; i < wonArray.length; i++){
                        let oppty = wonArray[i];

                        let opptyYear = oppty.expected_close_date.substr(0,4);
                        let opptyMonth = oppty.expected_close_date.substr(5,2);

                        let isRightObJ = (e) => e.year === opptyYear && e.month === opptyMonth;
                        let index = selectStageType.stagedef_set.findIndex(isRightObJ);

                        let rightObj = selectStageType.stagedef_set[index];
                        rightObj.opportunities.push(oppty);
                        this.countForecastTotal(oppty, rightObj);
                    }
                }
                else{
                    selectStageType.stagedef_set = this.getforecast_Stagedef_set(opptyList);
                }
                this.selectStageType = selectStageType;
            },
            findMonthName(m){
                let monthMap = new Map([
                    ['01', 'January'],
                    ['02','Febuary'],
                    ['03','March'],
                    ['04','April'],
                    ['05','May'],
                    ['06','June'],
                    ['07', 'July'],
                    ['08','August'],
                    ['09','September'],
                    ['10','October'],
                    ['11','November'],
                    ['12','December'],
                ]);

                return monthMap.get(m);
            },
            makeNewObj(opptyMonth, opptyYear){
                let obj = {
                        year: opptyYear,
                        month: opptyMonth,
                        monthName: this.findMonthName(opptyMonth),
                        opportunities: [],
                        total: {
                            omitted: 0,
                            bestCase: 0,
                            upside: 0,
                            commit: 0,
                            closed: 0
                        }
                }
                return obj;
            },
            getforecast_Stagedef_set(opptyList){
                
                let today = new Date();
                let firstM = (today.getMonth()+1).toString();
                let firstY = (today.getFullYear()).toString();
                let stagedef_set = [];

                if(opptyList.length > 0){
                    opptyList.sort(function(a, b){
                        return Date.parse(a.expected_close_date) - Date.parse(b.expected_close_date)
                    })

                    let lastM = opptyList[opptyList.length - 1].expected_close_date.substr(5,2);
                    let lastY = opptyList[opptyList.length - 1].expected_close_date.substr(0,4);

                    let deviation = parseInt(lastY) - parseInt(firstY);
                    
                    if(deviation > 0){
                        for(let i = 12; i >= firstM; i--){
                            let theMonth='';
                            if(i < 10)
                                theMonth = '0' + i.toString();
                            else
                                theMonth = i.toString();

                            let obj = this.makeNewObj(theMonth, firstY);
                            stagedef_set.push(obj);
                        }

                        for(let i = 1; i <= lastM; i++){
                            let theMonth='';
                            if(i < 10)
                                theMonth = '0' + i.toString();
                            else
                                theMonth = i.toString();

                            let obj = this.makeNewObj(theMonth, lastY);
                            stagedef_set.push(obj);
                        }

                        if(deviation >= 2){
                            for(let i = 1; i < deviation; i++){
                                let year = (parseInt(firstY) + i).toString();
                                for(let m = 1; m <= 12; m++){
                                    let theMonth='';
                                    if(m < 10)
                                        theMonth = '0' + m.toString();
                                    else
                                        theMonth = m.toString();

                                    let obj = this.makeNewObj(theMonth, year);
                                    stagedef_set.push(obj);
                                }
                            }
                        }
                    }
                    else if(deviation == 0){
                        
                        for(let i = parseInt(firstM); i <= parseInt(lastM); i++){
                            let theMonth='';
                            if(i < 10)
                                theMonth = '0' + i.toString();
                            else
                                theMonth = i.toString();

                            let obj = this.makeNewObj(theMonth, firstY);
                            stagedef_set.push(obj);
                        }
                    }
                    

                    stagedef_set.sort(function(a, b){
                        return a.month - b.month;
                    })
                    stagedef_set.sort(function(a, b){
                        return a.year - b.year;
                    })
                }
                else{
                    let theMonth='';

                    if(firstM < 10)
                        theMonth = '0' + firstM.toString();
                    else
                        theMonth = firstM.toString();
                    let obj = this.makeNewObj(theMonth, firstY);
                    stagedef_set.push(obj);	
                }
                
                return stagedef_set;
            },
            countForecastTotal(oppty, obj){
                if(oppty.closed_reason === 1){
                    obj.total.closed += parseFloat(oppty.amount);
                }
                else{
                    switch(oppty.forecast_category){
                        case 0:
                            obj.total.omitted += parseFloat(oppty.amount);
                            break;
                        case 1:
                            obj.total.bestCase += parseFloat(oppty.amount);
                            break;
                        case 2:
                            obj.total.upside += parseFloat(oppty.amount);
                            break;
                        case 3:
                            obj.total.commit += parseFloat(oppty.amount);
                            break;
                    }
                }
                // commit
                if (this.editOpptyData.forecast_category == 3) { 
                    this.oldMonthObj.total.commit = this.oldMonthObj.total.commit - parseFloat(this.editOpptyData.amount);
                }
                // upside
                if (this.editOpptyData.forecast_category == 2) { 
                    this.oldMonthObj.total.upside = this.oldMonthObj.total.upside - parseFloat(this.editOpptyData.amount);
                }
                // best case
                if (this.editOpptyData.forecast_category == 1) { 
                    this.oldMonthObj.total.bestCase = this.oldMonthObj.total.bestCase - parseFloat(this.editOpptyData.amount);
                }
            },
            getThisMonthMs(){
                let today = new Date();
                let nowYear = (today.getFullYear()).toString();
                let nowMonth = today.getMonth()+1;
                if(nowMonth < 10)
                    nowMonth = '0' + nowMonth.toString();
                else
                    nowMonth = nowMonth.toString();

                let thisMonthMs = Date.parse(nowYear + '-' + nowMonth);
                return thisMonthMs;
            },
            // all view
            countTotal(){
                this.amount = 0;
                this.dealsCount = 0;
                if (this.mode.kanban == true) {
                    this.kanbanOpptyList.forEach(oppty => {
                        this.amount += parseFloat(oppty.amount * 100);
                    });
                    this.amount = this.amount / 100;
                    this.dealsCount = this.kanbanOpptyList.length;
                } else if (this.mode.list == true) {
                    this.listOpptyList.forEach(oppty => {
                        this.amount += parseFloat(oppty.amount * 100);
                    });
                    this.amount = this.amount / 100;
                    this.dealsCount = this.listOpptyList.length;
                } else if (this.mode.forecast == true) { 
                    this.forecastList.forEach(oppty => {
                        this.amount += parseFloat(oppty.amount * 100);
                    });
                    this.amount = this.amount / 100;
                    this.dealsCount = this.forecastList.length;
                }
            },
            // onResize: function () {
            //     let windowH = window.innerHeight - 200; //table高度
            //     let objH = 70;
            //     this.itemPerPage = parseInt(windowH/objH);
            // },
            getColor: function(data) {
                this.colorQ = data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            async viewKanban() {
                if(this.pagemode == "kanban")
                {
                    return
                }
                try{
                    await this.removeScrollEvent_forecast()
                }
                // eslint-disable-next-line
                catch{}
                await(this.first_run = false)
                this.mode.kanban = true;
                this.mode.forecast = false;
                this.mode.list = false;
                this.pagemode = "kanban";
                if(this.noAnyStageType){
                    return;
                }
                this.stageTypeList = this.stageTypeList.filter(el => el.id != "all")
                if(this.selectStageType.id == 'all')
                    this.selectStageType = this.stageTypeList[0];
                // await this.get_current_filter()
                this.getKanbanData();
                await(this.first_run = true)
            },
            async viewList() {
                if(this.pagemode == "list")
                {
                    return
                }
                await (this.first_run = false);
                this.mode.list = true;
                this.mode.kanban = false;
                this.mode.forecast = false;
                this.pagemode = "list"
                this.page = 1
                if(this.noAnyStageType){
                    return;
                }
                this.stageTypeList.unshift({"id":"all","stage_type_name":i18n.t('GENERAL.ALL')})
                // await this.get_current_filter()
                this.getListData();
                await (this.first_run = true);
            },
            async viewForecast() {
                if(this.pagemode == "forecast")
                {
                    return
                }
                try{
                    await this.removeScrollEvent()
                }
                // eslint-disable-next-line
                catch{}
                await (this.showForecast = false);
                await (this.first_run = false);
                this.mode.forecast = true;
                this.mode.kanban = false;
                this.mode.list = false;
                this.pagemode = "forecast"
                if(this.noAnyStageType){
                    return;
                }
                this.stageTypeList.unshift({"id":"all","stage_type_name":i18n.t('GENERAL.ALL')})
                // await this.get_current_filter()
                this.getForecastViewData()
                await (this.showForecast = true);
                await (this.first_run = true);
            },
            async dragEnd (old_stage) {
                this.loading_content = true;
                this.showResult = false;
                this.checkConsoleLog(["dragEnd"]);
                var new_stage = this.kanbanData.stagedef_set.find(d=>d.oppty_set.find(e=>e.id === this.opptyData.id));
                this.checkConsoleLog(["dragEnd",new_stage]);
                if (new_stage != undefined) {
                    this.checkConsoleLog(["dragEnd",old_stage,new_stage,this.opptyData]);
                    if (old_stage.id != new_stage.id) {
                        let data = {
                            id: this.opptyData.id,
                            name: this.opptyData.name,
                            current_stage: {
                                id: new_stage.id,
                                sequence_number: new_stage.sequence_number,
                                stage_name: new_stage.stage_name,
                                stage_color: new_stage.stage_color
                            } 
                        };
                        this.checkConsoleLog(["dragEnd",data]);
                        await OpportunityDataService.updateCurrentStage(data)
                        .then(response => {
                            this.checkConsoleLog(["dragEnd","update",response]);
                            let old_stage_index = this.kanbanData.stagedef_set.findIndex(s => {return s.id == this.opptyData.current_stage.id})
                            let new_stage_index = this.kanbanData.stagedef_set.findIndex(s => {return s.id == response.data.current_stage.id})

                            this.kanbanData.stagedef_set[old_stage_index].stage_oppty_count --;
                            if (this.kanbanData.stagedef_set[old_stage_index].stage_oppty_count == 0) {
                                this.kanbanData.stagedef_set[old_stage_index].stage_oppty_count = '0';
                            }
                            this.kanbanData.stagedef_set[old_stage_index].stage_amount = this.kanbanData.stagedef_set[old_stage_index].stage_amount - this.opptyData.amount;

                            this.kanbanData.stagedef_set[new_stage_index].stage_oppty_count ++;
                            this.kanbanData.stagedef_set[new_stage_index].stage_amount = this.kanbanData.stagedef_set[new_stage_index].stage_amount + parseFloat(this.opptyData.amount);
                            
                            var oppty_index = this.kanbanData.stagedef_set[new_stage_index].oppty_set.findIndex(item => {return item.id == data.id});
                            this.kanbanData.stagedef_set[new_stage_index].oppty_set[oppty_index].current_stage = response.data.current_stage;
                            this.checkConsoleLog(["dragEnd","update over"]);
                        })
                        .catch((response)=>{
                            this.checkConsoleLog(["dragEnd","error",response]);
                            this.showErrorDialog(response)
                        })
                        .finally(()=>{
                            this.checkConsoleLog(["dragEnd","finally"]);
                            this.canDragCard();
                        })
                    }
                    else{
                        this.checkConsoleLog(["dragEnd","sameStage"]);
                        this.canDragCard();
                    }
                } 
                else {
                    this.checkConsoleLog(["dragEnd","new_stage == undefined"]);
                    this.canDragCard();
                }
            },
            dragStart(deals) {
                this.canDrag_kanban = false;
                this.opptyData = deals;
                this.showResult = true;
                this.checkConsoleLog(["dragStart",this.canDrag_kanban,this.opptyData,this.showResult,deals]);
            },
            dragStart_Forcast(item){
                this.canDrag_forecast = false,
                this.editOpptyData = item;
                this.showResult = true;
                this.checkConsoleLog(["dragStart_Forcast",this.canDrag_forecast,this.editOpptyData,this.showResult,item]);
            },
            dragEnd_Forcast(stage){
                this.oldMonthObj = stage;
                this.checkConsoleLog(["dragEnd_Forcast",stage]);
                if(!this.isWin && !this.isLose){
                    this.checkConsoleLog(["dragEnd_Forcast",this.isWin,this.isLose]);
                    this.newMonthObj = this.forecastviewData.stagedef_set.find(d=>d.opportunities.find(e=>e.id === this.editOpptyData.id));
                    this.checkConsoleLog(["dragEnd_Forcast","newMonthObj",this.newMonthObj]);
                    this.showResult = false;
                    this.editDealDateDialog = true;
                    this.editDate = `${this.newMonthObj.year}-${('0'+this.newMonthObj.month).substr(-2, 2)}-01`;
                    this.canDragCard();
                }
                this.drag_Forcast_month = [stage.month,this.newMonthObj.month];
                this.drag_Forcast_year = [stage.year,this.newMonthObj.year];
                this.checkConsoleLog(["dragEnd_Forcast",this.drag_Forcast_month,this.drag_Forcast_year]);
            },
            canDragCard(){
                this.loading_content = false;

                if(this.mode.forecast){
                    this.canDrag_forecast = true;
                }
                else if(this.mode.kanban){
                    this.canDrag_kanban = true;
                }
            },
            getIndex(array, obj) {
                let index = array.findIndex(item => {
                    return item.id === obj.id
                });
                return index;
            },
            countTotalAmount(stage_def) {
                let allAmount = 0;
                for(let i = 0; i < stage_def.dealNumbers; i++) {
                    allAmount = allAmount + parseFloat(stage_def.opportunity_set[i].amount);
                }
                return allAmount;
            },
            updateOpptyList() {
                let index = this.getIndex(this.opportunityList, this.opptyData)
                this.opportunityList[index] = this.opptyData;
            },
            setStage(stage) {
                stage.dealNumbers = stage.opportunity_set.length.toString();
                stage.totalAmount = this.countTotalAmount(stage);
                let stageIndex = this.getIndex(this.selectStageType.stagedef_set, stage);
                this.selectStageType.stagedef_set[stageIndex] = stage;
            },
            async filterByStageTypeAndOwner() {
                await (this.showForecast = false);
                this.currentType = this.getIndex(this.stageTypeList, this.selectStageType);

                if (this.selectOwner.id == 0) {
                    await (this.filterOpptyList = this.opportunityList.filter(oppty => oppty.stage_type == this.selectStageType.id));
                } else {
                    await (this.filterOpptyList = this.opportunityList.filter(oppty => oppty.stage_type == this.selectStageType.id && oppty.owner.id == this.selectOwner.id));
                }

                if (this.mode.kanban == true) {
                    await this.kanbanFilter();
                    await this.arrangeKanbanDeals(this.kanbanOpptyList);
                } else if (this.mode.list == true) {
                    await (this.listOpptyList = this.filterOpptyList);
                } else if (this.mode.forecast == true){
                    await this.forecastFilter();
                    await this.arrangeForecastDeals(this.forecastList);
                }

                this.countTotal();
                await (this.showForecast = true);
            },
            // Custom View
            async getTables(){
                this.tables = [];
                this.customViews.forEach(e => {
                    if(e.owner == null){
                        let item = {
                            id: e.id,
                            name: e.definition[0].name,
                            is_default: false,
                            fields: e.definition[0].fields,
                            owner: e.owner,
                            view_id : e.definition[0].id,
                        }
                        
                        let index;
                        if(e.definition[0].is_default == true){
                            item.is_default = true;
                            this.currentTable = item;
                        }
                        else{
                            index = e.definition[0].is_default.indexOf(String(this.selectStageType.id))
                            if(index != -1){
                                this.currentTable = item;
                            } 
                        }
                        
                        this.tables.push(item)
                    }
                    else if(e.owner != null){
                        e.definition.forEach(table => {
                            if(table.stage_type_id == this.selectStageType.id){
                                let item = {
                                    id: e.id,
                                    name: table.name,
                                    is_default: table.is_default,
                                    fields: table.fields,
                                    owner: e.owner,
                                    view_id : table.id,
                                }
                                if(item.is_default){
                                    this.currentTable = item;
                                }

                                let duplicate = this.tables.find(e => e.fields == item.fields);
                                if(duplicate == undefined){
                                    this.tables.push(item)
                                }
                            }
                        })
                    }
                });
                if(this.tables.length == 1){
                    this.currentTable = this.tables[0]
                }
                this.tables.reverse()
                this.getHeader();
            },
            async getHeader(){
                await this.getCFdef();
                let customWidthData = await this.getWidth("Opportunity",this.currentTable);
                let cfKey = this.customField ? Object.keys(this.customField) : []
                // check user input is from combobox
                if(typeof(this.currentTable) == 'object'){
                    await (this.loading_content = true);
                    let array = [];
                    let select = {
                        value: "data-table-select",
                        align: "center",
                        width: '100px',
                    };
                    let action = {
                        text: i18n.t('GENERAL.ACTIVE') ,
                        value: "action",
                        align: "center",
                        width: '150px',
                        sortable: false,
                    };
                    
                    this.currentTable.fields.forEach(e => {
                        let item = {
                            text: '',
                            value: e.value,
                            sequence: e.sequence_number,
                            align: 'center',
                            sortable: true
                        };
                        // if(this.currentTable.fields.length >= 8){
                        //     item.width = '200px';
                        // }
                        
                        if(e.is_customfield && this.CFdef != null){
                            if(this.CFdef[e.value] != undefined){
                                item.text = e.text;
                                item.value = 'custom_field_' + e.value;
                                item.prop = e.value;
                                item.array_num = this.CFdef[e.value].array_num;
                                item.type = (e.value).substr(0,3)
                                this.cf_header.push(item);
                            }
                        }
                        else{
                            item.text = this.getItemNameFromList(opptysFieldList , e.value);
                        }
                        if(e.is_customfield && cfKey.indexOf(e.value)!=-1){
                            array.push(item);
                        }
                        if(!e.is_customfield){
                            array.push(item);
                        }
                        // array.push(item);
                    })
                    array.sort(function(a, b){
                        return a.sequence_number - b.sequence_number
                    });
                    array.unshift(select);
                    array.push(action);
                    this.dealsHeaders = array;
                    this.sortByDesc = [];
                    this.sortBy = [];
                    this.headerData.id = this.currentTable.view_id;
                    this.headerData.name = this.currentTable.name;
                    this.headerData.value = array;
                    this.headerData.page = "Opportunity";
                    this.headerData.customWidth = customWidthData;
                    await (this.loading_content = false);
                    const title = localStorage.getItem('title');
                    document.title = title+' : '+this.currentTable.name +' – UpDay';
                }
            },
            getHeaderwithWidth(data){
                this.dealsHeaders = data;
            },
            // Dialog
            onAddActivity() {
                this.activityDialog = true;
            },
            onEmitActivityDialog(val) {
                this.activityDialog = false;
                if (val) {
                    this.showSuccessDialog();
                }
            },
            showCopyDealDialog(rowData){
                this.addDealDialog = true;
                let copyData = {
                    name: rowData.name,
                    primary_contact: rowData.primary_contact_id || rowData.oppty_contact_id ? {
                            "id": rowData.oppty_contact_id? rowData.oppty_contact.id:rowData.primary_contact.id,
                            "full_name":rowData.oppty_contact_id ? rowData.oppty_contact.__chineseName__:rowData.primary_contact.__chineseName__
                        }:null,
                    oppty_org: rowData.oppty_org_id ? {
                            "id":rowData.oppty_org.id,
                            "name":rowData.oppty_org.name,
                            "company_number":null
                        }:null,
                    stage_type: {
                            id: rowData.stage_type_id,
                            stage_type_name: rowData.stage_type_name,
                            stagedef_set: {
                                id: rowData.stage_def_id,
                                sequence_number: rowData.sequence_number,
                                stage_name: rowData.stage_name,
                                stage_color: rowData.stage_color
                            }
                        },
                    amount: rowData.amount,
                    expected_close_date: rowData.expected_close_date,
                    current_stage: rowData.sequence_number,
                    forecast_category: rowData.forecast_category,
                    custom_field: rowData.custom_field,
                }
                this.addDealData = copyData;
            },
            showAddDealDialog(stage_def, time) {
                if (this.stageTypeList[0].stage_type_name != i18n.t('DEAL.NO_DEAL_STAGE')) {
                    this.addDealDialog = true;
                    if (stage_def == 0) {
                        this.addDealData = {
                            name: null,
                            primary_contact: null,
                            oppty_org: null,
                            stage_type: {
                                id: this.selectStageType.id != 'all' ? this.selectStageType.id : this.default_process.id,
                                stage_type_name: this.selectStageType.id != 'all' ? this.selectStageType.stage_type_name : this.default_process.stage_type_name,
                                stagedef_set: {
                                    id: this.selectStageType.id != 'all' ? this.selectStageType.stagedef_set[0].id : this.default_process.stagedef_set[0].id,
                                    sequence_number: 1,
                                    stage_name: this.selectStageType.id != 'all' ? this.selectStageType.stagedef_set[0].stage_name : this.default_process.stagedef_set[0].stage_name,
                                    stage_color: this.selectStageType.id != 'all' ? this.selectStageType.stagedef_set[0].stage_color : this.default_process.stagedef_set[0].stage_color
                                }
                            },
                            amount: null,
                            expected_close_date: null,
                            forecast_category: 1,
                        }
                    } 
                    else {
                        this.addDealData = {
                            name: null,
                            primary_contact: null,
                            oppty_org: null,
                            stage_type: {
                                id: this.selectStageType.id,
                                stage_type_name: this.selectStageType.stage_type_name,
                                stagedef_set: {
                                    id: stage_def.id,
                                    sequence_number: stage_def.sequence_number,
                                    stage_name: stage_def.stage_name,
                                    stage_color: stage_def.stage_color
                                }
                            },
                            amount: null,
                            expected_close_date: null,
                            forecast_category: 1,
                        }
                    }
                    if(time != undefined){
                        if (time.month < 10)
                            var month = '0' + time.month.toString();
                        else
                            month = time.month;

                        this.addDealData.expected_close_date = time.year +'-'+ month + '-01'; 
                    }
                } else {
                    this.showErrorDialog(i18n.t('DEAL.ERROR'))
                }
            },
            onEmitAddDealDialog(val) {
                this.addDealDialog = false;
                this.selectedRows = [];
                if (val) {
                    this.successMode = 'ADD';
                    this.showSuccessDialog();
                }
            },
            onEmitNewDealData(data) {
                this.newDealData = data;
                if(this.selectOwner.id != 0 && this.selectOwner.id != this.newDealData.owner.id)
                {
                    return
                }
                
                if(this.mode.kanban == true){
                    let stage = this.kanbanData.stagedef_set.find(stage => stage.id == data.current_stage.id);
                    if (stage != undefined) {
                        stage.oppty_set.unshift(data);
                        stage.stage_amount = stage.stage_amount + parseFloat(data.amount);
                        stage.stage_oppty_count ++;
                        this.amount = this.amount + parseFloat(data.amount);
                        this.dealsCount ++;
                    }
                } 
                else if(this.mode.list == true){
                    if(this.selectStageType.id == data.stage_type || this.selectStageType.id == 'all'){
                        // data.updated_by = data.owner;
                        // data.created_by = data.owner;
                        // data.stage_type_name = this.stageTypeList.filter(el=>el.id == data.stage_type)[0].stage_type_name
                        // this.listOpptyList.unshift(data);
                        // this.amount = this.amount + parseFloat(data.amount);
                        // this.dealsCount ++;
                        this.reloadData();
                    }
                }
                else if (this.mode.forecast == true) {
                    if(this.selectStageType.id == data.stage_type || this.selectStageType.id == 'all'){
                        var data_year = new Date(data.expected_close_date).getFullYear();
                        var data_month = new Date(data.expected_close_date).getMonth() + 1;

                        let stage = this.forecastviewData.stagedef_set.find(stage => {
                            if (stage.year == data_year && stage.month == data_month)
                                return true;
                        });

                        if (stage != undefined) {
                            stage.opportunities.unshift(data);

                            if (data.forecast_category == 3)
                                stage.total.commit = stage.total.commit + parseFloat(data.amount);
                            if (data.forecast_category == 2)
                                stage.total.upside = stage.total.upside + parseFloat(data.amount);
                            if (data.forecast_category == 1)
                                stage.total.bestCase = stage.total.bestCase + parseFloat(data.amount);
                        } 
                        else {
                            this.getForecastViewData();
                        }

                        this.amount = this.amount + parseFloat(data.amount);
                        this.dealsCount ++;
                    }
                }
            },
            async showToDealDialog(item, subitem) {
                this.originStage = subitem;
                this.originAmount = item.amount;
                if (this.mode.forecast == true) {
                    this.originYear = new Date(item.expected_close_date).getFullYear();
                    this.originMonth = new Date(item.expected_close_date).getMonth() + 1;
                }
                await (this.opptyData = item);
                await (this.toDealDialog = true);
            },
            onEmitToDealDialog(months,change_amount,newYear) {
                this.toDealDialog = false;
                if(this.mode.forecast == true && months.length >1 ){
                    let month_dict = {1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June", 7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December"}
                    let last_FC = this.forecastviewData.stagedef_set[this.forecastviewData.stagedef_set.length -1]
                    let current_year = last_FC.year
                    let current_month = last_FC.month
                    if(newYear-current_year != 0 && newYear != this.forecastviewData.stagedef_set[0].year)
                    {
                        this.getForecastViewData();
                    }
                    else if(months[1]<this.forecastviewData.stagedef_set[0].month){
                        this.getForecastViewData();
                    }
                    else
                    {
                        if(current_month-months[1] == 1 && current_month<12)
                        {
                            this.forecastviewData.stagedef_set.push({'month':current_month+1,'monthName':month_dict[current_month+1],'opportunities':[],'total':{'bestCase':0,'closed':0,'commit':0,'omitted':0,'upside':0},'year':current_year})
                        }
                        else if(current_month-months[1] == 0 && current_month<11)
                        {
                            this.forecastviewData.stagedef_set.push({'month':months[1]+1,'monthName':month_dict[months[1]+1],'opportunities':[],'total':{'bestCase':0,'closed':0,'commit':0,'omitted':0,'upside':0},'year':current_year})
                            this.forecastviewData.stagedef_set.push({'month':months[1]+2,'monthName':month_dict[months[1]+2],'opportunities':[],'total':{'bestCase':0,'closed':0,'commit':0,'omitted':0,'upside':0},'year':current_year})
                        }else if(months[1]>=this.forecastviewData.stagedef_set[0].month && months[1] < 11){
                            return
                        }
                        else{
                            this.getForecastViewData();
                        }
                    }
                }
                if(this.mode.forecast == true && change_amount){
                    months.forEach(item => {
                        let obj = this.forecastviewData.stagedef_set.find(e => e.month == item);
                        let promise = new Promise((resolve) => {
                            for (let prop in obj.total){
                                this.$set(obj.total, prop, 0);
                            }
                            resolve('ok');
                        })
                        promise.then((msg)=>{
                            if(msg == 'ok'){
                                obj.opportunities.forEach(e => {
                                    if(e.closed_reason === 1){
                                        obj.total.closed += parseFloat(e.amount);
                                    }
                                    else{
                                        switch(e.forecast_category){
                                            case 0:
                                                obj.total.omitted += parseFloat(e.amount);
                                                break;
                                            case 1:
                                                obj.total.bestCase += parseFloat(e.amount);
                                                break;
                                            case 2:
                                                obj.total.upside += parseFloat(e.amount);
                                                break;
                                            case 3:
                                                obj.total.commit += parseFloat(e.amount);
                                                break;
                                        }
                                    }
                                })
                            }
                        })
                    })
                }
                if(change_amount){
                    this.amount = this.amount + change_amount;
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            async onEmitSuccessDialog() {
                if(this.successMode == 'WIN'){
                    if(this.mode.forecast == true){
                        this.isWin = false;
                    }
                }
                else if(this.successMode == 'LOSE'){
                    if(this.mode.forecast == true){
                        this.isLose = false;
                    }
                }

                this.successDialog = false;
                this.successMode = '';
            },
            onDelete (item) {
                this.deleteOpptyIds = "";
                if(item!=null){
                    this.deleteItem = item;
                    this.showMessageDialog(i18n.t('DEAL.MESSAGE'));
                }else{
                    let closeOppty = '';
                    for(let i = 0; i < this.selectedRows.length; i++)
                    {
                        this.deleteOpptyIds += `${this.selectedRows[i].id},`;
                        if(this.selectedRows[i].closed_reason != null && this.selectedRows[i].closed_reason != 0){
                            closeOppty = closeOppty.length > 0 ? closeOppty +','+ this.selectedRows[i].name : closeOppty + this.selectedRows[i].name;
                        }
                    }
                    this.deleteOpptyIds = this.deleteOpptyIds.slice(0,-1);
                    if(closeOppty.length>0){
                        this.showErrorDialog(`${i18n.t('DEAL.DELETE_ERROR')}<br>${closeOppty}`);
                    }else{
                        this.showMessageDialog(i18n.t('DEAL.MESSAGE'));
                    }
                    }
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val && this.deleteOpptyIds == "") { 
                    OpportunityDataService.delete(this.deleteItem.id)
                    .then(() => {
                        if(this.amount > 0 && this.amount != 0){
                            this.amount = this.amount - this.deleteItem.amount;
                        }
                        else{
                            this.amount = 0;
                        }
                        this.dealsCount --;
                        var index = this.listOpptyList.findIndex(item => { return item.id == this.deleteItem.id; })
                        this.listOpptyList.splice(index, 1);
                    })
                    this.selectedRows = [];
                    this.successDialog = true; 
                }else if(val && this.deleteOpptyIds != ""){
                    OpportunityDataService.bulkdelete(this.deleteOpptyIds)
                    .then(() => {
                        this.deleteOpptyIds = "";
                        this.getListData();
                        this.selectedRows = [];
                    })
                }
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            log: function(evt) {
                window.console.log(evt);
            },
            async winDrop() {
                this.loseDealData = this.winList[0];
                this.loseDealData.closed_reason = 1
                this.showResult = false;
                this.loseReason = true;
                this.winList = [];
                this.canDragCard();
            },
            loseDrop() {
                this.loseDealData = this.loseList[0];
                this.showResult = false;
                this.loseReason = true;
                this.loseList = [];
                this.canDragCard();
            },
            onEmitLoseReasonDialog(val) {
                if (val) {
                    if(this.mode.kanban == true){
                        this.opptyData = this.loseDealData;
                        let index = this.kanbanData.stagedef_set.findIndex(item => {return item.id == this.opptyData.current_stage.id})
                        this.kanbanData.stagedef_set[index].stage_oppty_count --;
                        if (this.kanbanData.stagedef_set[index].stage_oppty_count == 0) {
                            this.kanbanData.stagedef_set[index].stage_oppty_count = '0';
                        }
                        this.kanbanData.stagedef_set[index].stage_amount = this.kanbanData.stagedef_set[index].stage_amount - this.opptyData.amount;

                        this.amount = this.amount - parseFloat(this.opptyData.amount);
                        this.dealsCount --;

                        this.loseReason = false;
                        this.showSuccessDialog();
                    }
                    else if(this.mode.forecast == true){
                        this.amount = this.amount - parseFloat(this.loseDealData.amount);
                        this.dealsCount --;
                        if (this.loseDealData.forecast_category == 3) {
                            this.oldMonthObj.total.commit = this.oldMonthObj.total.commit - this.loseDealData.amount;
                        } else if (this.loseDealData.forecast_category == 2) {
                            this.oldMonthObj.total.upside = this.oldMonthObj.total.upside - this.loseDealData.amount;
                        } else if (this.loseDealData.forecast_category == 1) {
                            this.oldMonthObj.total.bestCase = this.oldMonthObj.total.bestCase - this.loseDealData.amount;
                        }

                        if (this.loseDealData.closed_reason == 1) {
                            let stage = this.forecastviewData.stagedef_set.find(item => {
                                if (item.year == new Date(this.loseDealData.closed_date).getFullYear() && item.month == new Date(this.loseDealData.closed_date).getMonth() + 1) {
                                    return true;
                                }
                            });

                            if (stage != undefined) {
                                stage.opportunities.unshift(this.loseDealData);
                                stage.total.closed = stage.total.closed + parseFloat(this.loseDealData.amount);
                            }

                        }
                        this.loseReason = false;                        
                    }
                } 
                else {
                    if(this.mode.kanban == true){
                        this.opptyData = this.loseDealData;
                        let index = this.kanbanData.stagedef_set.findIndex(item => {return item.id == this.opptyData.current_stage.id})
                        
                        this.kanbanData.stagedef_set[index].oppty_set.unshift(this.opptyData);
                        this.loseDealData.closed_reason = null
                        this.loseReason = false;
                    }
                    else if(this.mode.forecast == true){
                        this.loseDealData.closed_reason = null;
                        this.oldMonthObj.opportunities.unshift(this.loseDealData);
                        this.loseReason = false;
                    }
                }
                this.isWin = false
                this.isLose = false
            },
            async onEmitEditDealDate(val, newDate){
                if(val){
                    this.showSuccessDialog();
                    // this.countForecastTotal(this.editOpptyData, this.newMonthObj);
                    if(newDate != undefined){
                        this.drag_Forcast_month[1] = new Date(newDate).getMonth() + 1;
                        this.drag_Forcast_year[1] = new Date(newDate).getFullYear();
                    }
                    if(this.drag_Forcast_month[1]>this.drag_Forcast_month[0] | this.drag_Forcast_year[1]> this.drag_Forcast_year[0])
                    {
                        this.onEmitToDealDialog(this.drag_Forcast_month,false,this.drag_Forcast_year[1])
                    }
                    switch(this.editOpptyData.forecast_category){
                    case 0:
                        this.oldMonthObj.total.omitted = this.oldMonthObj.total.omitted - this.editOpptyData.amount
                        this.newMonthObj.total.omitted = this.newMonthObj.total.omitted + this.editOpptyData.amount
                        break;
                    case 1:
                        this.oldMonthObj.total.bestCase = this.oldMonthObj.total.bestCase - this.editOpptyData.amount
                        this.newMonthObj.total.bestCase = this.newMonthObj.total.bestCase + this.editOpptyData.amount
                        break;
                    case 2:
                        this.oldMonthObj.total.upside = this.oldMonthObj.total.upside - this.editOpptyData.amount
                        this.newMonthObj.total.upside = this.newMonthObj.total.upside + this.editOpptyData.amount
                        break;
                    case 3:
                        this.oldMonthObj.total.commit = this.oldMonthObj.total.commit - this.editOpptyData.amount
                        this.newMonthObj.total.commit = this.newMonthObj.total.commit + this.editOpptyData.amount
                        break;
                    default:
                        break;
                    }
                }
                else{
                    // add deal back to old month obj
                    this.oldMonthObj.opportunities.unshift(this.editOpptyData);
                    // delete from new month obj
                    var index = this.newMonthObj.opportunities.findIndex(oppty => { return oppty.id == this.editOpptyData.id; });
                    this.newMonthObj.opportunities.splice(index, 1);
                }
                this.editDealDateDialog = false;
                this.drag_Forcast_month = [];
                this.drag_Forcast_year = null;
            },
            addProcess() {
                this.stageTypeData = {
                    stage_type_name: '',
                    type:null   
                }
                this.stageDefData = [
                    {
                        sequence_number: '',
                        stage_name: '',
                        stage_probability: '',
                        zombie_day: '',
                        guideline: '',
                        stage_color: '',
                        isMore: false,
                    }
                ]
                this.addProcessDialog = true;
            },
            onEmitAddProcessDialog(val) {
                if (val) {
                    this.showSuccessDialog();
                }
                this.addProcessDialog = false;
            },
            onEmitAddProcessData(data) {
                this.stageTypeList.push(data)
                if (this.stageTypeList[0].id == undefined) {
                    this.stageTypeList.splice(0, 1);
                    this.selectStageType = this.stageTypeList[0];
                    this.filterByStageTypeAndOwner();
                }
            },
            showEditTableDialog(){
                this.editTableDialog = true;
            },
            onEmitEditTableDialog(val){
                if(val){
                    this.showSuccessDialog();
                }
                this.editTableDialog = false;
            },
            onEmitEditTable(data){
                if(this.editMode == 'EDIT'){
                    this.currentTable.name = data.name;
                    this.currentTable.fields = data.fields;
                }
                else if(this.editMode == 'COPY'){
                    this.tables.unshift(data);
                    this.currentTable = data;
                }
                this.getHeader();
            },
            async onEmitRefresh(){
                this.reloadData();
            },
            showBulkEditDialog(){
                this.bulkEditDialog = true;
            },
            async onEmitBulkEditDialog(val,editData){
                this.bulkEditDialog = false;
                if(val){
                    if(val == 'editing'){
                        this.loading = true;
                        await BulkEditDataService.editOppty(editData);
                        await(this.selectedRows = []);
                        await(this.getListData());
                        await(this.loading = false);
                    }
                }
            },
        },
        watch: {
            "default_filter": function(){
                if(this.first_run){
                    if (this.mode.kanban == true){
                            this.getKanbanData();
                        }
                        else if (this.mode.list == true){
                            this.page = 1;
                            this.getListData();
                        }
                        else if (this.mode.forecast == true){
                            this.getForecastViewData();
                        }
                }
            },
            winList: function(){
                if(this.winList.length > 0)
                    this.isWin = true;
            },
            loseList: function(){
                if(this.loseList.length > 0)
                    this.isLose  = true;
            },
            toDealDialog: function(){
                if(this.toDealDialog == false) {
                    // if (this.opptyData.closed_reason != 1) {
                    //     this.amount = this.amount + parseFloat(this.opptyData.amount);
                    //     this.dealsCount ++;
                    // }

                    if (this.mode.kanban == true) {
                        // var stage_index = this.kanbanData.stagedef_set.findIndex(item => {return item.id == this.opptyData.current_stage.id});
                        // this.kanbanData.stagedef_set[stage_index].oppty_set.unshift(this.opptyData);
                        // this.kanbanData.stagedef_set[stage_index].stage_oppty_count ++;
                        // this.kanbanData.stagedef_set[stage_index].stage_amount = this.kanbanData.stagedef_set[stage_index].stage_amount + parseFloat(this.opptyData.amount);

                        this.kanbanData.stagedef_set[this.originStage.sequence_number - 1].stage_amount = this.kanbanData.stagedef_set[this.originStage.sequence_number - 1].stage_amount - this.originAmount;
                        var stage_index = this.kanbanData.stagedef_set.findIndex(item => {return item.id == this.opptyData.current_stage.id});

                        if (this.opptyData.current_stage.id != this.originStage.id) {
                            var index = this.originStage.oppty_set.findIndex(oppty => { return oppty.id == this.opptyData.id; });
                            this.kanbanData.stagedef_set[this.originStage.sequence_number - 1].oppty_set.splice(index, 1);
                            this.kanbanData.stagedef_set[this.originStage.sequence_number - 1].stage_oppty_count --;
                            if (this.kanbanData.stagedef_set[this.originStage.sequence_number - 1].stage_oppty_count == 0) {
                                this.kanbanData.stagedef_set[this.originStage.sequence_number - 1].stage_oppty_count = '0';
                            }
                            
                            this.kanbanData.stagedef_set[stage_index].oppty_set.unshift(this.opptyData);
                            this.kanbanData.stagedef_set[stage_index].stage_oppty_count ++;
                            this.kanbanData.stagedef_set[stage_index].stage_amount = this.kanbanData.stagedef_set[stage_index].stage_amount + parseFloat(this.opptyData.amount);
                        } else {
                            this.kanbanData.stagedef_set[stage_index].stage_amount = this.kanbanData.stagedef_set[stage_index].stage_amount + parseFloat(this.opptyData.amount);
                        }

                    } else if (this.mode.list == true) {
                        let oppty_index = this.listOpptyList.findIndex(item => {return item.id == this.opptyData.id})
                        this.listOpptyList[oppty_index] = this.opptyData;
                    } else if (this.mode.forecast == true) {
                        let y = new Date(this.opptyData.expected_close_date).getFullYear();
                        let m = new Date(this.opptyData.expected_close_date).getMonth() + 1;

                        if (this.originYear != y || this.originMonth != m) {
                            if (this.opptyData.closed_reason != 1) {
                                if (this.opptyData.forecast_category == 3) {
                                    this.originStage.total.commit = this.originStage.total.commit - this.opptyData.amount;
                                } else if (this.opptyData.forecast_category == 2) {
                                    this.originStage.total.upside = this.originStage.total.upside - this.opptyData.amount;
                                } else if (this.opptyData.forecast_category == 1) {
                                    this.originStage.total.bestCase = this.originStage.total.bestCase - this.opptyData.amount;
                                }

                                let oppty_index = this.originStage.opportunities.findIndex(obj => { return obj.id == this.opptyData.id; });
                                this.originStage.opportunities.splice(oppty_index, 1);
                            }

                            let stage_index = this.forecastviewData.stagedef_set.findIndex(item => {  
                                if (item.year == y && item.month == m)
                                    return true;
                            });

                            var stage = this.forecastviewData.stagedef_set.find(item => {
                                let y = new Date(this.opptyData.expected_close_date).getFullYear();
                                let m = new Date(this.opptyData.expected_close_date).getMonth() + 1;
                                
                                if (item.year == y && item.month == m)
                                    return true;
                            });
                            if(stage != undefined)
                            {
                                stage.opportunities.unshift(this.opptyData);

                            if (this.opptyData.forecast_category == 3) {
                                let commitAmount = stage.total.commit + parseFloat(this.opptyData.amount);
                                this.$set(this.forecastviewData.stagedef_set[stage_index].total, "commit", commitAmount);
                            } else if (this.opptyData.forecast_category == 2) {
                                let upsideAmount = stage.total.upside + parseFloat(this.opptyData.amount);
                                this.$set(this.forecastviewData.stagedef_set[stage_index].total, "upside", upsideAmount);
                            } else if (this.opptyData.forecast_category == 1) {
                                let bestCaseAmount = stage.total.bestCase + parseFloat(this.opptyData.amount);
                                this.$set(this.forecastviewData.stagedef_set[stage_index].total, "bestCase", bestCaseAmount);
                            }
                            }
                        }
                    }
                }
            },
            "page":async function () {
                await this.getListData()
            },
            "searchDeals":async function () {
                if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.page = 1
                    this.getListData();
                    this.awaitingSearch = false;
                }, 1000);
                }
                this.awaitingSearch = true;  
            },
            "sortByDesc":async function () {
                this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
                if(this.sortByDesc.length != 0){
                    for(let i in this.sortByDesc)
                    {
                        if(this.sortByDesc[i] == true)
                        {
                            this.sortByForSearch[i] = '-'+this.sortByForSearch[i];
                        }
                    }
                    await this.getListData();
                }
            },
            "selectStageType":async function(){
                if(this.mode.list){
                    this.sortByForSearch = []
                }
            },
            "currentTable":async function(){
                this.sortByForSearch = []
            }
            // listOpptyList: 
        },
    }
</script>